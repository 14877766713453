import React, { useState } from "react";
import Navbar from "./Navbar";
import { Grid, Typography, Box, TextField, Button, Link } from "@mui/material";
import axios from "axios"; // Make sure to install axios: npm install axios

const App = () => {
  return (
    <div className="App">
      <Navbar />
      <Hero />
      <HowItWorks />
      {/* <CTA /> */}
      <Footer />
    </div>
  );
};

const Hero = () => {
  return (
    <Box
      sx={{
        px: { xs: 2, sm: 5, md: 35, lg: 35, xl: 35 }, // Responsive padding
        py: 10,
      }}
    >
      <Grid
        container
        spacing={4}
        alignItems="center"
        justifyContent={"space-between"}
      >
        <Grid item xs={12} md={6}>
          <Typography
            variant="h3" // Change this variant to see the effect
            component="h1"
            gutterBottom
            align="left"
            sx={{ fontWeight: "600" }}
          >
            Invest in European{" "}
            <span style={{ color: "#4db6a4" }}>Real Estate</span> with Ease
          </Typography>

          <Typography
            variant="h6" // Change this variant to see the effect
            component="p"
            fontWeight={400}
            gutterBottom
            align="left"
            marginTop={2}
          >
            Start building your real estate portfolio and earn passive income,
            starting at just $100 per share.
          </Typography>
          <Box marginTop={4}>
            <Typography
              variant="h6" // Change this variant to see the effect
              component="p"
              fontWeight={400}
              gutterBottom
              align="left"
            >
              Coming soon to
            </Typography>

            <img
              src="/stores.png"
              alt="Real Estate Investment"
              loading="lazy"
              style={{
                width: "100%",
                maxWidth: "400px", // Adjusts the size of the image
                display: "block",
              }}
            />
          </Box>
        </Grid>

        <Grid item xs={12} md={6}>
          <img
            src="/Home.png"
            alt="Real Estate Investment"
            loading="lazy"
            style={{
              width: "50%",
              maxWidth: "50%", // Adjusts the size of the image
              transform: "rotate(-15deg)", // Rotates the image by 15 degrees to the left
              borderRadius: "8px",
              display: "block",
              marginLeft: "auto",
              marginRight: "auto",
            }}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

const HowItWorks = () => {
  return (
    <Box sx={{ px: { xs: 2, sm: 4, md: 6, lg: 8 }, py: 8 }}>
      <Typography
        color="primary"
        variant="h3"
        fontWeight={600}
        textAlign="center"
        gutterBottom
      >
        How it Works
      </Typography>
      <Section
        title={"Browse"}
        heading={"Access real estate opportunities across Europe"}
        subheading={
          "Sign up in under 5 minutes and start investing in a curated selection of expert-vetted properties"
        }
        imagePath={"/browse.png"}
      />
      <Section
        title={"Invest"}
        heading={"Own shares in properties starting at just $100"}
        subheading={
          "With Ardi, investing is just a few clicks away, no matter where you are in the world"
        }
        imagePath={"/invest.png"}
        layout="right"
      />
      <Section
        title={"Earn"}
        heading={"Collect consistent rental income"}
        subheading={
          "Enjoy effortless passive income with consistent rental payments, directly to your Ardi wallet"
        }
        imagePath={"/earn.png"}
        layout="left"
      />
      <Section
        title={"Exit"}
        heading={"Sell your shares when the property appreciates"}
        subheading={
          "Unlock liquidity when you need it by selling your shares on our secondary market or cashing out at property maturity"
        }
        imagePath={"/exit.png"}
        layout="right"
      />
    </Box>
  );
};

const Section = ({
  title,
  heading,
  subheading,
  imagePath,
  layout = "left",
}) => {
  return (
    <Box sx={{ py: 5 }}>
      <Grid
        container
        spacing={4}
        alignItems="center"
        direction={{ xs: "column", md: "row" }} // Column on small screens, row on medium and up
      >
        <Grid
          item
          xs={12}
          md={6}
          sx={{
            display: "flex",
            justifyContent: {
              xs: "center",
              md: layout === "left" ? "flex-end" : "flex-start",
            },
            order: { xs: 2, md: layout === "left" ? 1 : 2 }, // Ensure image is second on small screens
          }}
        >
          <img
            src={imagePath}
            alt="Real Estate Investment"
            loading="lazy"
            style={{
              width: "100%",
              maxWidth: "100%",
              height: "auto",
              display: "block",
            }}
          />
        </Grid>
        <Grid
          item
          xs={12}
          md={6}
          sx={{ order: { xs: 1, md: layout === "left" ? 2 : 1 } }} // Ensure text is first on small screens
        >
          <Typography
            variant="h4"
            component="h1"
            gutterBottom
            align="left"
            color="primary"
            sx={{ fontWeight: "600" }}
          >
            {title}
          </Typography>
          <Typography
            variant="h5"
            component="p"
            fontWeight={600}
            gutterBottom
            align="left"
          >
            {heading}
          </Typography>
          <Typography
            variant="h6"
            component="p"
            fontWeight={400}
            gutterBottom
            color="#808080"
            align="left"
          >
            {subheading}
          </Typography>
        </Grid>
      </Grid>
    </Box>
  );
};

const CTA = () => {
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const validateEmail = (email) => {
    const re = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return re.test(String(email).toLowerCase());
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validateEmail(email)) {
      setMessage("Please enter a valid email address.");
      return;
    }

    setIsLoading(true);
    try {
      const response = await axios.post(
        "https://7c4qp6fahcxhnupw655nfm62ce0ntaps.lambda-url.eu-north-1.on.aws/",
        { email: email },
        {
          headers: {
            "access-control-allow-origin": "*",
            "Content-type": "application/json; charset=UTF-8",
          },
        }
      );
      console.log("Response:", response);
      setMessage("Thank you for joining our waitlist!");
      setEmail("");
    } catch (error) {
      console.error("Error submitting email:", error);
      if (error.response) {
        console.error("Error response:", error.response);
        setMessage(
          `An error occurred (${error.response.status}). Please try again later.`
        );
      } else if (error.request) {
        console.error("Error request:", error.request);
        setMessage("No response received from server. Please try again later.");
      } else {
        console.error("Error message:", error.message);
        setMessage(
          "An error occurred while sending the request. Please try again later."
        );
      }
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Box
      sx={{
        px: { xs: 2, sm: 4, md: 6, lg: 8 },
        py: 25,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        backgroundColor: "#F9F7F1",
      }}
    >
      <Grid container spacing={4} justifyContent="center">
        <Grid item xs={12} md={6} textAlign="center">
          <Typography
            variant="h3"
            component="h1"
            gutterBottom
            sx={{ fontWeight: "600" }}
          >
            Ready to build your real estate empire?
          </Typography>
          <Typography variant="h6" component="p" fontWeight={400} gutterBottom>
            Join our exclusive waitlist and be notified once Ardi launches.
          </Typography>
        </Grid>
        <Grid item xs={12} md={8} textAlign="center">
          <Box
            component="form"
            onSubmit={handleSubmit}
            sx={{
              display: "flex",
              flexDirection: { xs: "column", sm: "row" },
              justifyContent: "center",
              gap: 2,
            }}
          >
            <TextField
              variant="outlined"
              placeholder="Enter your email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              sx={{
                flexGrow: 1,
                maxWidth: { sm: "400px" },
                bgcolor: "white",
              }}
            />
            <Button
              type="submit"
              variant="contained"
              color="primary"
              disabled={isLoading}
              sx={{
                height: "56px",
                px: 4,
                color: "white",
              }}
            >
              {isLoading ? "Submitting..." : "Get early access"}
            </Button>
          </Box>
          {message && (
            <Typography
              variant="body1"
              sx={{
                mt: 2,
                color: message.includes("error")
                  ? "error.main"
                  : "success.main",
              }}
            >
              {message}
            </Typography>
          )}
        </Grid>
      </Grid>
    </Box>
  );
};

const Footer = () => {
  return (
    <Box
      sx={{
        py: 4,
        px: 2,
        backgroundColor: "#F9F7F1",
        color: "#000000",
        textAlign: "center",
      }}
    >
      <Typography variant="body1" gutterBottom>
        &copy; {new Date().getFullYear()} Ardi. All rights reserved.
      </Typography>
      <Typography variant="body2" gutterBottom>
        <Link href="mailto:mad64@mail.aub.edu" color="inherit">
          Contact
        </Link>
      </Typography>
    </Box>
  );
};
export default App;
